globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"JvVk0Q2CHH8-XX49RQJXx"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/web/dev";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import { isPrd } from './src/shared/util/env'
import { config } from './sentry.common.config'

Sentry.init({
  ...config,
  // Add optional integrations for additional features
  integrations: [
    Sentry.replayIntegration(),
  ],

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: isPrd() ? 0.1 : undefined,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: isPrd() ? 1.0 : undefined,
});
